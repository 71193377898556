import { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  List,
  Button,
  Tooltip,
  Divider,
  AvatarGroup,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  lighten,
  styled
} from "@mui/material";
import { formatDistance, subMinutes, subHours } from "date-fns";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import Label from "src/components/Label";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import AlarmTwoToneIcon from "@mui/icons-material/AlarmTwoTone";
import { Link as RouterLink } from "react-router-dom";
import { getAllNamespace } from "src/content/applications/KnowledgeBase/knowledge-base-api";
import { AIChatBot, AIChatBotAvatarMap, AIChatBotType } from "./scripts/AIChatBot";

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.success.lighter};
          color: ${theme.colors.success.main};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          margin-left: auto;
          margin-right: auto;
    `
);

const MeetingBox = styled(Box)(
  ({ theme }) => `
          background-color: ${lighten(theme.colors.alpha.black[10], 0.5)};
          margin: ${theme.spacing(2)} 0;
          border-radius: ${theme.general.borderRadius};
          padding: ${theme.spacing(2)};
    `
);

const RootWrapper = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2.5)};
  `
);

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
        &.MuiButtonBase-root {
            margin: ${theme.spacing(1)} 0;
        }
  `
);

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};
                color: ${theme.colors.alpha.black[50]};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }
  `
);

const ChatBotRowItem: React.FC<{ bot: AIChatBot; selectedChatBot: AIChatBot; onSelectedBotChanged: any }> = ({
  bot,
  onSelectedBotChanged,
  selectedChatBot
}) => {
  return (
    <ListItemWrapper key={bot.botName} selected={bot && bot.botName == selectedChatBot?.botName} onClick={() => onSelectedBotChanged(bot)}>
      <ListItemAvatar>
        <Avatar src={AIChatBotAvatarMap[bot.type]} />
      </ListItemAvatar>
      <ListItemText
        sx={{
          mr: 1
        }}
        primaryTypographyProps={{
          color: "textPrimary",
          variant: "h5",
          noWrap: true
        }}
        secondaryTypographyProps={{
          color: "textSecondary",
          noWrap: true
        }}
        primary={bot?.slackName}
        secondary="Hey there, how are you today? Is it ok if I call you?"
      />
      {bot.hasNewMessage && (
        <Label color="primary">
          <b>new</b>
        </Label>
      )}
    </ListItemWrapper>
  );
};

function SidebarContent(props) {
  const { bots, onSelectedBotChanged, selectedChatBot, onFrameworkChanged, framework, isShowSourceDocs, onIsShowSourceDocsChanged } = props;
  const user = {
    name: "Michael Hart",
    avatar: "/static/images/avatars/1.jpg",
    jobtitle: "Software Developer"
  };

  const [botSearch, setBotSearch] = useState("");

  const [state, setState] = useState({
    invisible: true
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
    // console.log({ "event.target.checked": event.target.checked });
    if (event.target.checked) {
      onFrameworkChanged("PRIVATE_GPT");
    } else {
      onFrameworkChanged("CHAT_GPT");
    }
  };

  const handleChangeShowSourceDocChanged = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
    onIsShowSourceDocsChanged(!!event.target.checked);
  };

  const [currentTab, setCurrentTab] = useState<string>("all");

  const tabs = [
    { value: "all", label: "All" },
    { value: "namespaces", label: "Namespaces" },
    { value: "bots", label: "Bots" }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <RootWrapper>
      <Box display="flex" alignItems="flex-start">
        <Avatar alt={user.name} src={user.avatar} />
        <Box
          sx={{
            ml: 1.5,
            flex: 1
          }}
        >
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
            <Box>
              <Typography variant="h5" noWrap>
                {user.name}
              </Typography>
              <Typography variant="subtitle1" noWrap>
                {user.jobtitle}
              </Typography>
            </Box>
            <IconButton
              sx={{
                p: 1
              }}
              size="small"
              color="primary"
            >
              <SettingsTwoToneIcon fontSize="small" />
            </IconButton>
          </Box>

          <FormControlLabel
            control={<Switch checked={framework == "PRIVATE_GPT"} onChange={handleChange} name="invisible" color="primary" />}
            label={framework}
          />

          <FormControlLabel
            control={<Switch checked={isShowSourceDocs} onChange={handleChangeShowSourceDocChanged} name="ShowSourceDocs" color="primary" />}
            label="Show Docs?"
          />
        </Box>
      </Box>

      <TextField
        sx={{
          mt: 2,
          mb: 1
        }}
        size="small"
        fullWidth
        value={botSearch}
        onChange={(e) => setBotSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          )
        }}
        placeholder="Search..."
      />

      <Typography
        sx={{
          mb: 1,
          mt: 2
        }}
        variant="h3"
      >
        Chats
      </Typography>

      <TabsContainerWrapper>
        <Tabs onChange={handleTabsChange} value={currentTab} variant="scrollable" scrollButtons="auto" textColor="primary" indicatorColor="primary">
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>

      <Box mt={2}>
        {currentTab === "all" && (
          <List disablePadding component="div">
            {bots
              .filter((b) => b.botName.toLowerCase().includes(botSearch.toLowerCase()))
              .map((bot) => (
                <ChatBotRowItem bot={bot} key={bot.botName} onSelectedBotChanged={onSelectedBotChanged} selectedChatBot={selectedChatBot} />
              ))}
          </List>
        )}
        {currentTab === "namespaces" && (
          <List disablePadding component="div">
            {bots
              .filter((bot) => bot.type == AIChatBotType.NAMESPACE && bot.botName.toLowerCase().includes(botSearch.toLowerCase()))
              .map((bot) => (
                <ChatBotRowItem bot={bot} key={bot.botName} onSelectedBotChanged={onSelectedBotChanged} selectedChatBot={selectedChatBot} />
              ))}
          </List>
        )}
        {currentTab === "bots" && (
          <List disablePadding component="div">
            {bots
              .filter((bot) => bot.type == AIChatBotType.BOT_CONFIG && bot.botName.toLowerCase().includes(botSearch.toLowerCase()))
              .map((bot) => (
                <ChatBotRowItem bot={bot} key={bot.botName} onSelectedBotChanged={onSelectedBotChanged} selectedChatBot={selectedChatBot} />
              ))}
          </List>
        )}
      </Box>
    </RootWrapper>
  );
}

export default SidebarContent;
