import { Box, Avatar, Typography, Card, styled, Divider } from "@mui/material";

import { formatDistance, format, subDays, subHours, subMinutes } from "date-fns";
import ScheduleTwoToneIcon from "@mui/icons-material/ScheduleTwoTone";
import { useEffect, useMemo, useRef, useState } from "react";
import { sampleChatsDialog } from "./scripts/sample-chat-dialogs";
import { ChatRole } from "./scripts/chat-roles.enum";
import { ChatMessage, ChatVote } from "./scripts/ChatMessage";
import { ChatDialog } from "./scripts/ChatDialog";

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const MessageRowItem: React.FC<{
  isBotThinking: boolean;
  chat: ChatMessage;
  isLastRow: boolean;
  handleVoteMessage: (chat: ChatMessage, vote: ChatVote) => void;
}> = ({ chat, handleVoteMessage, isLastRow, isBotThinking }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (chat.role == ChatRole.BOT) {
    return (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" py={3}>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          src="/static/images/avatars/ai.png"
        />
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="flex-start"
          ml={2}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <CardWrapperSecondary style={{ whiteSpace: "pre-wrap" }}>{chat.message}</CardWrapperSecondary>
          {!chat.message.includes("Hello, I am a tax calculator") && (
            <Box
              width="100%"
              height="25px"
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="flex-end"
              ml={2}
              style={{ paddingRight: "30px", marginTop: "-15px" }}
            >
              {(chat.vote == ChatVote.UP || (!chat.vote && ((isHovered && !isLastRow) || (isLastRow && !isBotThinking)))) && (
                <img
                  height="25px"
                  src={chat.vote == ChatVote.UP ? "/static/images/icons/green-like.svg" : "/static/images/icons/like.svg"}
                  style={{ paddingRight: "5px" }}
                  onClick={() => handleVoteMessage(chat, ChatVote.UP)}
                />
              )}
              {(chat.vote == ChatVote.DOWN || (!chat.vote && ((isHovered && !isLastRow) || (isLastRow && !isBotThinking)))) && (
                <img
                  height="25px"
                  src={chat.vote == ChatVote.DOWN ? "/static/images/icons/red-like.svg" : "/static/images/icons/like.svg"}
                  style={{ transform: "rotate(180deg)" }}
                  onClick={() => handleVoteMessage(chat, ChatVote.DOWN)}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    //user
    return (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-end" py={3}>
        <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="flex-end" mr={2}>
          <CardWrapperPrimary style={{ whiteSpace: "pre-wrap" }}>{chat.message}</CardWrapperPrimary>
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          alt="Michael Hart"
          src="/static/images/avatars/user_2.png"
        />
      </Box>
    );
  }
};

function ChatContent({
  dialog,
  handleVoteMessage,
  isBotThinking
}: {
  dialog: ChatDialog;
  isBotThinking: boolean;
  handleVoteMessage: (chat: ChatMessage, vote: ChatVote) => void;
}) {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // console.log("scroll down");
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "end" });
  }, [dialog?.length, dialog?.[dialog?.length - 1]?.message]);

  return (
    <Box p={3}>
      {(dialog || []).map((chat, index) => (
        <MessageRowItem
          isLastRow={dialog.length == index + 1}
          chat={chat}
          key={chat.role + chat.message + chat.time.getTime()}
          handleVoteMessage={handleVoteMessage}
          isBotThinking={isBotThinking}
        />
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
}

export default ChatContent;
