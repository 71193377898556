import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";

import TopBarContent from "./TopBarContent";
import BottomBarContent from "./BottomBarContent";
import SidebarContent from "./SidebarContent";
import ChatContent from "./ChatContent";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";

import Scrollbar from "src/components/Scrollbar";

import { Box, styled, Divider, Drawer, IconButton, useTheme, Snackbar } from "@mui/material";
import { getAllNamespace } from "src/content/applications/KnowledgeBase/knowledge-base-api";
import { getBotResponse, getSlackBotConfigs, updateVoteChat } from "src/content/applications/NewTraining/apis";
import { AIChatBot, AIChatBotType } from "./scripts/AIChatBot";
import { ChatDialog } from "./scripts/ChatDialog";
import { ChatMessage, ChatVote } from "./scripts/ChatMessage";
import { ChatRole } from "./scripts/chat-roles.enum";
import { SocketChatMessageDTO } from "./scripts/SocketChatMessage.dto";
import { socket } from "src/socket";
import { SocketBotResponseMessageDTO } from "./scripts/socket-bot-response-message.dto";
import { useSearchParams } from "react-router-dom";
import { BotDialog } from "./scripts/BotDialog";
import { ChatConversation } from "./scripts/ChatConversation.dto";
import axios from "axios";

const RootWrapper = styled(Box)(
  ({ theme }) => `
       height: calc(100vh);
       display: flex;
`
);

const Sidebar = styled(Box)(
  ({ theme }) => `
        width: 300px;
        background: ${theme.colors.alpha.white[100]};
        border-right: ${theme.colors.alpha.black[10]} solid 1px;
`
);

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

function ChatApp() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [tempQuestion, setTempQuestion] = useState("");
  const [socketId, setSocketId] = useState("");
  const [pingTimerId, setPingTimerId] = useState<NodeJS.Timeout>(null);
  const [framework, setFramework] = useState("PRIVATE_GPT");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isConnected, setIsConnected] = useState(true);

  const [isShowSourceDocs, setIsShowSourceDocs] = useState(false);

  const [chatBots, setChatBots] = useState<AIChatBot[]>([]);
  const [newBotMessage, setNewBotMessage] = useState<SocketBotResponseMessageDTO>(null);
  const [selectedChatBot, setSelectedChatBot] = useState<AIChatBot | null>(null);

  const [dialogs, setDialogs] = useState<BotDialog[]>([]);
  const [pendingChatMessages, setPendingMessages] = useState<ChatMessage[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const isMiniMode = () => {
    return searchParams.get("mode") == "mini";
  };

  const handleVoteMessage = async (chat: ChatMessage, vote: ChatVote) => {
    console.log("update vote " + vote);
    const previousVote = chat.vote;
    const newDialogs = [...dialogs];

    try {
      newDialogs.forEach((d) => {
        if (d.botName == selectedChatBot.botName) {
          d.dialog.forEach((m) => {
            if (m.messageId == chat.messageId) {
              m.vote = vote;
            }
          });
        }
      });
      setDialogs([...newDialogs]);
      const updatedConversation = await updateVoteChat(chat.messageId, vote);
      if (!updatedConversation) {
        console.log("Update vote failed!");
        newDialogs.forEach((d) => {
          if (d.botName == selectedChatBot.botName) {
            d.dialog.forEach((m) => {
              if (m.messageId == chat.messageId) {
                m.vote = previousVote;
              }
            });
          }
        });
        setDialogs([...newDialogs]);
      }
    } catch (error) {
      console.log("Update vote failed!", error);
      newDialogs.forEach((d) => {
        if (d.botName == selectedChatBot.botName) {
          d.dialog.forEach((m) => {
            if (m.messageId == chat.messageId) {
              m.vote = previousVote;
            }
          });
        }
      });
      setDialogs([...newDialogs]);
    }
  };

  const fetchBots = async () => {
    const chatBots: AIChatBot[] = [];
    const botConfigs = await getSlackBotConfigs();
    console.log({ botConfigs });
    botConfigs.forEach((bot) =>
      chatBots.push({
        type: AIChatBotType.BOT_CONFIG,
        namespace: bot.namespace,
        slackName: bot.bot_slack_name,
        botName: bot.bot_name,
        prompt: bot.prompt,
        promptVersion: bot.prompt_version,
        updatedTime: new Date()
      })
    );
    setChatBots(chatBots);
    let isFoundInitBot = false;

    if (searchParams.get("botName")) {
      const botName = searchParams.get("botName");
      const bot = chatBots.find((bot) => bot.slackName == botName);
      if (bot) {
        console.log("Select bot with name: " + botName);
        setSelectedChatBot(bot);
        isFoundInitBot = true;
      }
    }

    if (!isFoundInitBot && searchParams.get("namespace")) {
      const botName = `ns-${searchParams.get("namespace")}`;
      const bot = chatBots.find((bot) => bot.botName == botName);
      if (bot) {
        console.log("Select bot with name: " + botName);
        setSelectedChatBot(bot);
        isFoundInitBot = true;
      }
    }

    if (!isFoundInitBot) {
      console.log("Select the first bot!");
      setSelectedChatBot(chatBots[0]);
    }

    const newDialogs: BotDialog[] = [];
    chatBots.forEach((bot) => {
      if (bot.slackName == "TaxRelief.AI") {
        newDialogs.push({
          botName: bot.botName,
          isBotThinking: false,
          dialog: [
            {
              message: "Hello, I am a tax calculator bot designed to help you calculate your taxes based on the type of your company and your adjusted profit.",
              messageId: "xx",
              role: ChatRole.BOT,
              time: new Date()
            }
          ]
        });
      } else {
        newDialogs.push({
          botName: bot.botName,
          dialog: [],
          isBotThinking: false
        });
      }
    });
    setDialogs([...newDialogs]);
  };

  const handleBotMessageFromSocket = async (payload: SocketBotResponseMessageDTO) => {
    let botMessage: ChatMessage = {
      role: ChatRole.BOT,
      message: payload.message,
      time: new Date(),
      messageId: payload.messageId
    };

    if (payload.botName == "CURRENT_BOT" && selectedChatBot) {
      payload.botName = selectedChatBot.botName;
    }

    if (payload.botName == "ALL_BOT") {
      console.log("Saving pending message!");
      const botMessage: ChatMessage = {
        role: ChatRole.BOT,
        message: payload.message,
        time: new Date(),
        messageId: new Date().getTime().toString()
      };
      setPendingMessages([botMessage]);
      return;
    }

    let isBotThinking = true;
    const isDone = botMessage.message == "[DONE]";
    let sourceDocumentsStr = "";

    if (payload?.sourceDocs && payload.sourceDocs.length > 0) {
      sourceDocumentsStr =
        "\n======================\nSource documents:\n" +
        payload.sourceDocs.map((doc, index) => `${index + 1}. ${doc.file_name}\n${doc.text.replaceAll("\n", " ")}`).join("\n\n");
    }

    const currentDialog = dialogs.find((d) => d.botName == payload.botName)?.dialog;
    if (currentDialog) {
      if (currentDialog.length > 1) {
        const lastMessage = currentDialog[currentDialog.length - 1];
        if (lastMessage.message == "thinking...") {
          currentDialog.pop();
          if (["[DONE]", "There is an error!"].includes(botMessage.message)) {
            isBotThinking = false;
          }
        } else if (lastMessage.messageId == botMessage.messageId) {
          if (["[DONE]", "There is an error!"].includes(botMessage.message)) {
            isBotThinking = false;
            currentDialog.pop();
            botMessage.message = isShowSourceDocs ? lastMessage.message + sourceDocumentsStr : lastMessage.message;
          } else {
            currentDialog.pop();
            botMessage.message = lastMessage.message + botMessage.message;
          }
        }
      }

      currentDialog.push(botMessage);
      // setDialogMap(dialog);
      setDialogs([
        ...dialogs.filter((d) => d.botName != payload.botName),
        {
          botName: payload.botName,
          dialog: currentDialog,
          isBotThinking
        }
      ]);

      // If finished send request to save the chat
      const sentMessageBot = chatBots.find((b) => b.botName == payload.botName);
      if (isDone) {
        console.log({ docs: payload.sourceDocs });
        const previousMessage = currentDialog[currentDialog.length - 2].message;
        const saveChatConversationPayload: ChatConversation = {
          messageId: botMessage.messageId,
          botResponse: botMessage.message,
          question: previousMessage,
          botName: payload.botName,
          slackName: sentMessageBot.slackName,
          history: currentDialog.map((m) => m.message),
          botConfig: {
            prompt: sentMessageBot.prompt,
            promptVersion: sentMessageBot.promptVersion,
            namespace: sentMessageBot.namespace
          },
          framework,
          sourceDocuments: payload.sourceDocs
        };
        const url = `${process.env.REACT_APP_AI_TRAINER_URL}/chat/save`;
        try {
          await axios.post(url, saveChatConversationPayload);
        } catch (error) {
          console.log("Save message to dashboard failed!", error);
        }
      }

      if (selectedChatBot.botName != payload.botName && sentMessageBot) {
        sentMessageBot.hasNewMessage = true;
        setChatBots(chatBots);
      }
    }
  };

  useEffect(() => {
    socket.connect();
    fetchBots();

    if (searchParams.get("framework")) {
      const frw = searchParams.get("framework");
      setFramework(frw);
    }

    socket.on("connect", () => {
      console.log("Connected to socket: " + socket.id); // true
      setSocketId(socket.id);
      setIsConnected(true);

      const timerId = setInterval(() => {
        console.log("keep-alive", socket.id);
        socket.emit("keep-alive");
      }, 20000);

      setPingTimerId(timerId);
    });

    socket.on("disconnect", () => {
      if (pingTimerId) {
        console.log("clear keep-alive");
        clearInterval(pingTimerId);
        setPingTimerId(null);
      }
      console.log("disconnected"); // true
      setIsConnected(false);
    });

    socket.io.on("error", (error) => {
      console.log({ error });
    });

    socket.on("bot_message", (payload) => {
      setNewBotMessage(payload);
    });

    return () => {
      socket.disconnect();
      if (pingTimerId) {
        console.log("clear keep-alive");
        clearInterval(pingTimerId);
      }
    };
  }, []);

  // useEffect(() => {
  //   const latestMessage = botMessageEvents.pop();
  //   if (latestMessage) {
  //     handleBotMessageFromSocket(latestMessage);
  //   }
  // }, [botMessageEvents]);

  useEffect(() => {
    if (newBotMessage) {
      handleBotMessageFromSocket(newBotMessage);
    }
  }, [newBotMessage]);

  useEffect(() => {
    if (pendingChatMessages.length > 0 && dialogs.length > 0) {
      const newDialogs = [...dialogs];
      newDialogs.forEach((d) => {
        d.dialog.push(...pendingChatMessages);
      });

      setPendingMessages([]);
      setDialogs([...newDialogs]);
    }
  }, [pendingChatMessages, dialogs]);

  useEffect(() => {
    if (dialogs.length > 0) {
      const numOfMess = dialogs[0].dialog.length;
      const lastMessage = numOfMess > 0 ? dialogs[0].dialog[numOfMess - 1].message : "";
      const DISCONNECT_MESSAGE = "re-connecting...";
      if (isConnected) {
        // connected
        if (lastMessage == DISCONNECT_MESSAGE) {
          const newDialogs = [...dialogs];
          newDialogs.forEach((d) => {
            d.dialog.splice(d.dialog.length - 1, 1);
          });
          setDialogs([...newDialogs]);
          console.log({ newDialogs });
        }
      } else {
        //disconnected
        if (lastMessage != DISCONNECT_MESSAGE) {
          const botMessage: ChatMessage = {
            role: ChatRole.BOT,
            message: DISCONNECT_MESSAGE,
            time: new Date(),
            messageId: "zzz"
          };
          const newDialogs = [...dialogs];
          newDialogs.forEach((d) => {
            d.dialog.push(botMessage);
          });
          setDialogs([...newDialogs]);
          console.log({ newDialogs });
        }
      }
    }
  }, [isConnected, dialogs]);

  useEffect(() => {
    if (selectedChatBot && selectedChatBot.hasNewMessage) {
      const bot = chatBots.find((b) => b.botName == selectedChatBot.botName);
      if (bot) {
        bot.hasNewMessage = false;
        setChatBots(chatBots);
      }
    }
  }, [selectedChatBot]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  async function handleSubmitQuestion(e) {
    if (!tempQuestion) return;
    const question = tempQuestion;
    const userMessage: ChatMessage = {
      role: ChatRole.USER,
      message: tempQuestion,
      time: new Date(),
      messageId: ""
    };
    let botMessage: ChatMessage = {
      role: ChatRole.BOT,
      message: "thinking...",
      time: new Date(),
      messageId: ""
    };
    // const dialog = cloneDeep(dialogMap);
    console.log({ dialogs, selectedChatBot });
    const currentDialog = dialogs.find((d) => d.botName == selectedChatBot.botName)?.dialog;
    currentDialog.push(userMessage);
    currentDialog.push(botMessage);
    // dialog[selectedChatBot.botName] = currentDialog;
    // setDialogMap(dialog);
    setDialogs([
      ...dialogs.filter((d) => d.botName != selectedChatBot.botName),
      {
        botName: selectedChatBot.botName,
        dialog: currentDialog,
        isBotThinking: true
      }
    ]);
    const payload: SocketChatMessageDTO = {
      socketId,
      message: question,
      bot: selectedChatBot,
      framework,
      role: ChatRole.USER,
      isStream: true,
      history: currentDialog.map((c) => c.message).filter((m) => m != "thinking...")
    };
    socket.emit("client_message", payload);
    setTempQuestion("");
    // handleBotMessageFromSocket(payload);

    // let botResponse = "";
    // try {
    //   const response = await getBotResponse(question, selectedChatBot, framework);
    //   botResponse = response["responseText"];
    // } catch (error) {
    //   botResponse = error.message || "ERROR!";
    // }

    // botMessage = {
    //   role: ChatRole.BOT,
    //   message: botResponse
    // };
    // if (currentDialog[currentDialog.length - 1].message == "thinking...") {
    //   currentDialog.pop();
    // }
    // currentDialog.push(botMessage);
    // setDialogMap({ ...dialogMap });
  }

  const handleTypingQuestion = (question) => {
    // console.log("Question:", question);
    setTempQuestion(question);
  };

  return (
    <>
      <Helmet>
        <title>Messenger - Applications</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        {!isMiniMode() && (
          <DrawerWrapperMobile
            sx={{
              display: { lg: "none", xs: "inline-block" }
            }}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <Scrollbar>
              <SidebarContent
                bots={chatBots}
                framework={framework}
                onFrameworkChanged={(framework) => setFramework(framework)}
                isShowSourceDocs={isShowSourceDocs}
                onIsShowSourceDocsChanged={(isShow) => setIsShowSourceDocs(isShow)}
                selectedChatBot={selectedChatBot}
                onSelectedBotChanged={(bot) => {
                  setSelectedChatBot(bot);
                }}
              />
            </Scrollbar>
          </DrawerWrapperMobile>
        )}
        {!isMiniMode() && (
          <Sidebar
            sx={{
              display: { xs: "none", lg: "inline-block" }
            }}
          >
            <Scrollbar>
              <SidebarContent
                bots={chatBots}
                framework={framework}
                onFrameworkChanged={(framework) => setFramework(framework)}
                isShowSourceDocs={isShowSourceDocs}
                onIsShowSourceDocsChanged={(isShow) => setIsShowSourceDocs(isShow)}
                selectedChatBot={selectedChatBot}
                onSelectedBotChanged={(bot) => {
                  setSelectedChatBot(bot);
                }}
              />
            </Scrollbar>
          </Sidebar>
        )}
        <ChatWindow>
          <ChatTopBar
            sx={{
              display: { xs: "flex", lg: "inline-block" }
            }}
          >
            {!isMiniMode() && (
              <IconButtonToggle
                sx={{
                  display: { lg: "none", xs: "flex" },
                  mr: 2
                }}
                color="primary"
                onClick={handleDrawerToggle}
                size="small"
              >
                <MenuTwoToneIcon />
              </IconButtonToggle>
            )}
            <TopBarContent bot={selectedChatBot} />
          </ChatTopBar>
          <Box flex={1}>
            <Scrollbar>
              <ChatContent
                dialog={dialogs.find((d) => d.botName == selectedChatBot?.botName)?.dialog}
                handleVoteMessage={handleVoteMessage}
                isBotThinking={dialogs.find((d) => d.botName == selectedChatBot?.botName)?.isBotThinking}
              />
            </Scrollbar>
          </Box>
          <Divider />
          <BottomBarContent
            isBotThinking={dialogs.find((d) => d.botName == selectedChatBot?.botName)?.isBotThinking}
            isConnected={isConnected}
            handleClick={handleSubmitQuestion}
            tempQuestion={tempQuestion}
            handleQuestion={handleTypingQuestion}
          />
        </ChatWindow>
      </RootWrapper>

      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        action={
          <IconButton size="small" color="inherit" onClick={() => setShowToast(false)}>
            X
          </IconButton>
        }
      />
    </>
  );
}

export default ChatApp;
