import axios from "axios";
import { Dayjs } from "dayjs";
import { omitBy, isEmpty } from "lodash";
import moment from "moment";
import { AIChatBot } from "src/layouts/ChatLayout/scripts/AIChatBot";
import { BotConfig } from "src/layouts/ChatLayout/scripts/BotConfig";
import { ChatVote } from "src/layouts/ChatLayout/scripts/ChatMessage";
import { CategoryEnums, CHAT_PLATFORMS, CountryEnums, VALIDATION_STATUS } from "src/utils/constants";

const DEFAULT_BOT_NAME = "felic.ai";

export interface GetStatisticRequestDTO {
  source: string;
  botName?: string;
}

interface FilterOptionApiInput {
  botName?: string;
  page?: number;
  pageSize?: number;
  source: string;
  textSearch?: string;
  voteSearch?: string;
  country?: string;
  fromDate?: Dayjs;
  toDate?: Dayjs;
  category?: string;
  metadata?: string;
  isFindPublicComment?: boolean;
  isBadAdminResponse?: boolean;
  isFindVoteDownComment?: boolean;
}

interface QueryApiInput {
  botName?: string;
  page?: string;
  pageSize?: string;
  source: string;
  search?: string;
  validationStatus?: string;
  fromDate?: string;
  toDate?: string;
  metadata?: string;
}

function uniformDateParam(date: Dayjs): string | null {
  const DATE_FORMAT = "DD-MM-YYYY";

  if (date) {
    return moment(date.toDate()).format(DATE_FORMAT);
  }
  return null;
}

const generateMetadataFilter = (filter: FilterOptionApiInput): any[] => {
  const metadata = [];

  if (filter.category) {
    metadata.push({
      key: "Category",
      value: filter.category
    });
  }

  if (filter.country) {
    metadata.push({
      key: "Country",
      value: filter.country
    });
  }

  if (filter.isFindPublicComment) {
    metadata.push({
      key: "ReplyMode",
      value: "PublicComment"
    });
  }

  if (filter.isBadAdminResponse) {
    metadata.push({
      key: "AdminResponse",
      value: "AdminResponse-bad"
    });
  }

  if (filter.isFindVoteDownComment) {
    metadata.push({
      key: "UserVote",
      value: "UserVoteDown"
    });
  }

  if (filter.metadata) {
    const objs = JSON.parse(filter.metadata) as any[];
    metadata.push(
      ...objs.map((item) => {
        const keys = Object.keys(item);
        return { key: keys[0], value: item[keys[0]] };
      })
    );
  }

  return metadata;
};

const convertFilterOptionToFilterQuery = (filter: FilterOptionApiInput): QueryApiInput => {
  if (filter.voteSearch == VALIDATION_STATUS.ALL) {
    filter.voteSearch = "";
  }
  if (filter.country == CountryEnums.All) {
    filter.country = "";
  }
  if (filter.category == CategoryEnums.All) {
    filter.category = "";
  }
  const metadataObj = generateMetadataFilter(filter);
  const queryObj: QueryApiInput = {
    botName: filter.botName,
    page: filter.page?.toString() || "1",
    pageSize: filter.pageSize?.toString() || "10",
    source: filter.source,
    search: filter.textSearch?.toString(),
    validationStatus: filter.voteSearch?.toString(),
    fromDate: uniformDateParam(filter.fromDate),
    toDate: uniformDateParam(filter.toDate),
    metadata: metadataObj.length > 0 ? JSON.stringify(metadataObj) : null
  };
  return omitBy(queryObj, isEmpty);
};

export const fetchConversations = async (filter: FilterOptionApiInput) => {
  const queryObj = convertFilterOptionToFilterQuery(filter);

  const params = new URLSearchParams(queryObj as unknown as Record<string, string>);
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation?${params.toString()}`;
  const uploadedQuestionsResponse = await axios.get(url);
  return uploadedQuestionsResponse.data;
};

export const countConversation = async (filter: FilterOptionApiInput) => {
  const queryObj = convertFilterOptionToFilterQuery(filter);

  const params = new URLSearchParams(queryObj as unknown as Record<string, string>);
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/count?${params.toString()}`;
  const uploadedQuestionsResponse = await axios.get(url);
  return uploadedQuestionsResponse.data;
};

export const getStatistic = async (queryObj: GetStatisticRequestDTO) => {
  const params = new URLSearchParams(omitBy(queryObj, isEmpty));
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/statistic?${params.toString()}`;
  const { data } = await axios.get(url);
  return data;
};

export const postCreateTrainingItem = async (conversation) => {
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/${conversation._id}/training-item`;
  const { data } = await axios.post(url, { generated_by_email: localStorage.getItem("email") });
  return data.updatedConversation;
};

export const putUpdateConversationTrainingItem = async (conversationId, { emote, idealResponse }) => {
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/${conversationId}`;
  const { data } = await axios.put(url, { emote, ideal_response: idealResponse });
  return data.updatedConversation;
};

export const updateVoteChat = async (messageId: string, vote: ChatVote) => {
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/update-user-feedback`;
  const { data } = await axios.post(url, { userVote: vote, sourceId: messageId, source: CHAT_PLATFORMS.CHAT });
  return data;
};

export const exportConversations = async (filter: FilterOptionApiInput) => {
  const queryObj = convertFilterOptionToFilterQuery(filter);
  const params = new URLSearchParams(queryObj as unknown as Record<string, string>);
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/conversation/export?${params.toString()}`;
  const response = await axios.get(url);
  const blobFile = new Blob([response?.data], { type: "text/csv" });
  const downloadUrl = window.URL.createObjectURL(blobFile);
  return downloadUrl;
};

export const getSlackBotConfigs = async (): Promise<BotConfig[]> => {
  const url = `${process.env.REACT_APP_SLACK_BOT_URL}/api/slack-configs`;
  const { data } = await axios.get(url);
  return data;
};

export const getBotResponse = async (question: string, bot: AIChatBot, framework: string): Promise<BotConfig[]> => {
  const bodyData = {
    question,
    prompt: bot.prompt || "companyQaPrompt",
    promptVersion: bot.promptVersion || 3,
    history: [],
    // additionalParams: ,
    namespace: bot.namespace,
    framework
  };
  const url = `${process.env.REACT_APP_AI_TRAINER_URL}/gpt-api/query`;
  const { data } = await axios.post(url, bodyData);
  return data;
};
