import { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import axios from "axios";

import router from "src/router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline, Snackbar, Alert } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import Login from "src/content/applications/Login";

import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function fetchUserUsingToken(token) {
  return axios.get(`${process.env.REACT_APP_BOT_URL}/api/auth`, {
    headers: { Authorization: token }
  });
}

function App() {
  const content = useRoutes(router);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    const token = getCookie("auth_token");
    if (!token || token == "undefined") return;

    setToken(token);
    const fetchUser = async () => {
      try {
        const userResponse = await fetchUserUsingToken(token);
        const user = await userResponse.data;
        setUser(user);
        localStorage.setItem("fullname", `${user.first_name} ${user.last_name}`);
        localStorage.setItem("email", user.email);
      } catch (error) {
        setToken(null);
        setSnackbar({ severity: "error", content: "Authentication fails. Please try login again." });
      }
    };
    fetchUser();
  }, []);

  const handleLogin = async ({ email, password }) => {
    try {
      const loginResponseData = await axios.post(`${process.env.REACT_APP_BOT_URL}/api/auth`, { email, password });
      const { auth_token, user } = await loginResponseData.data;
      document.cookie = `auth_token=${auth_token}; path=/`;
      setToken(auth_token);
      setUser(user);
      localStorage.setItem("fullname", `${user.first_name} ${user.last_name}`);
      localStorage.setItem("email", user.email);
    } catch (error) {
      console.error("Error:", error);
      setSnackbar({ severity: "error", content: "Authentication fails. Please try login again." });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(null);
  };

  return (token || window.location.pathname === "/chat") ? (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  ) : (
    <>
      <Login handleLogin={handleLogin} />
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={!!snackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar ? snackbar.severity : "info"} sx={{ width: "100%" }}>
          {snackbar ? snackbar.content : ""}
        </Alert>
      </Snackbar>
    </>
  );
}
export default App;
