import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";
import ChatApp from "src/layouts/ChatLayout";

import SuspenseLoader from "src/components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import("src/content/overview")));

// Dashboards

const Crypto = Loader(lazy(() => import("src/content/dashboards/Crypto")));

// Applications

const Messenger = Loader(lazy(() => import("src/content/applications/Messenger")));
const Transactions = Loader(lazy(() => import("src/content/applications/Transactions")));
const UserProfile = Loader(lazy(() => import("src/content/applications/Users/profile")));
const UserSettings = Loader(lazy(() => import("src/content/applications/Users/settings")));

const AIBotsDashboard = Loader(lazy(() => import("src/content/applications/AIBots")));

const PromptsDashboard = Loader(lazy(() => import("src/content/applications/Prompts")));

const TrainingDashboard = Loader(lazy(() => import("src/content/applications/Training")));

const NewTrainingDashboard = Loader(lazy(() => import("src/content/applications/NewTraining")));
const KnowledgeBase = Loader(lazy(() => import("src/content/applications/KnowledgeBase")));

// Components

const Buttons = Loader(lazy(() => import("src/content/pages/Components/Buttons")));
const Modals = Loader(lazy(() => import("src/content/pages/Components/Modals")));
const Accordions = Loader(lazy(() => import("src/content/pages/Components/Accordions")));
const Tabs = Loader(lazy(() => import("src/content/pages/Components/Tabs")));
const Badges = Loader(lazy(() => import("src/content/pages/Components/Badges")));
const Tooltips = Loader(lazy(() => import("src/content/pages/Components/Tooltips")));
const Avatars = Loader(lazy(() => import("src/content/pages/Components/Avatars")));
const Cards = Loader(lazy(() => import("src/content/pages/Components/Cards")));
const Forms = Loader(lazy(() => import("src/content/pages/Components/Forms")));

// Status

const Status404 = Loader(lazy(() => import("src/content/pages/Status/Status404")));
const Status500 = Loader(lazy(() => import("src/content/pages/Status/Status500")));
const StatusComingSoon = Loader(lazy(() => import("src/content/pages/Status/ComingSoon")));
const StatusMaintenance = Loader(lazy(() => import("src/content/pages/Status/Maintenance")));

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/management/ai-bots" replace />
      },
      {
        path: "overview",
        element: <Navigate to="/" replace />
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />
          },
          {
            path: "404",
            element: <Status404 />
          },
          {
            path: "500",
            element: <Status500 />
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: "*",
        element: <Status404 />
      }
    ]
  },
  {
    path: "dashboards",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="crypto" replace />
      },
      {
        path: "crypto",
        element: <Crypto />
      },
      {
        path: "messenger",
        element: <Messenger />
      }
    ]
  },
  {
    path: "training",
    element: <SidebarLayout />,
    children: [
      {
        path: "validation",
        element: <NewTrainingDashboard />
      }
    ]
  },
  {
    path: "trainings",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <NewTrainingDashboard />
      }
    ]
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="transactions" replace />
      },
      {
        path: "transactions",
        element: <Transactions />
      },
      {
        path: "knowledge-base",
        element: <KnowledgeBase />
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="details" replace />
          },
          {
            path: "details",
            element: <UserProfile />
          },
          {
            path: "settings",
            element: <UserSettings />
          }
        ]
      },
      {
        path: "ai-bots",
        element: <AIBotsDashboard />
      },
      {
        path: "prompts",
        element: <PromptsDashboard />
      }
    ]
  },
  {
    path: "/components",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="buttons" replace />
      },
      {
        path: "buttons",
        element: <Buttons />
      },
      {
        path: "modals",
        element: <Modals />
      },
      {
        path: "accordions",
        element: <Accordions />
      },
      {
        path: "tabs",
        element: <Tabs />
      },
      {
        path: "badges",
        element: <Badges />
      },
      {
        path: "tooltips",
        element: <Tooltips />
      },
      {
        path: "avatars",
        element: <Avatars />
      },
      {
        path: "cards",
        element: <Cards />
      },
      {
        path: "forms",
        element: <Forms />
      }
    ]
  },
  {
    path: "/chat",
    element: <ChatApp />
  }
];

export default routes;
