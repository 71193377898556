import {useState} from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Button, CssBaseline, TextField, Link, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const LoginAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const LoginForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

export default function LoginPage(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  return (
    <LoginContainer maxWidth="xs">
      <CssBaseline />
      <div>
        <LoginAvatar>
          <LockOutlinedIcon />
        </LoginAvatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <LoginForm noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <SubmitButton
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => props.handleLogin({email, password})}
          >
            Sign In
          </SubmitButton>
        </LoginForm>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Powered by '}
          <Link color="inherit" href="https://sleek.com/">
            Sleek.
          </Link>
        </Typography>
      </Box>
    </LoginContainer>
  );
}
