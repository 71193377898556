import { ChatRole } from "./chat-roles.enum";

export enum ChatVote {
  UP = "up",
  DOWN = "down"
}

export interface ChatMessage {
  role: ChatRole;
  message: string;
  time: Date;
  messageId: string;

  vote?: ChatVote;
}
