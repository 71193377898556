export const CHAT_PLATFORMS = {
  SLACK: "Slack",
  ZENDESK: "Zendesk",
  CHAT: "Chat"
};

export enum VALIDATION_STATUS {
  UP = "up",
  DOWN = "down",
  NONE = "none",
  ALL = "--- Any Vote ---"
}

export enum CategoryEnums {
  All = "--- Any Category ---",
  Tax = "Tax",
  Payroll = "Payroll",
  TransactionalDocuments = "Transactional Documents",
  ReportingDocuments = "Reporting Documents",
  SleekSubscriptions = "Sleek Subscriptions",
  PlatformSubscriptions = "Platform Subscriptions",
  SalesSubscriptions = "Sales Subscriptions",
  ChangeOfAddress = "Change of Address",
  Accounting = "Accounting",
  Others = "Others"
}

export enum CountryEnums {
  All = "--- Any Country ---",
  Singapore = "SG",
  HongKong = "HK",
  Australia = "AU",
  UnitedKingdom = "UK"
}
