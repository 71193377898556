export enum AIChatBotType {
  NAMESPACE = "namespace",
  BOT_CONFIG = "bot_config"
}

export const AIChatBotAvatarMap = {
  [AIChatBotType.BOT_CONFIG]: "/static/images/icons/slack.png",
  [AIChatBotType.NAMESPACE]: "/static/images/icons/books.png"
};

export interface AIChatBot {
  type: AIChatBotType;
  namespace: string;
  botName: string; //bot_name to store and filter
  slackName: string; //bot_slack_name to display
  prompt: string;
  promptVersion: number; //prompt_version

  hasNewMessage?: boolean;
  updatedTime?: Date;
}
