// NotificationContext.js
import React, { ReactNode, createContext, useState } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

interface Notification {
  open: boolean;
  message: string;
  type: AlertColor; // 'success', 'error', 'warning', 'info'
}

interface NotificationContextType {
  showNotification: (message: string, type: AlertColor) => void;
}

interface Props {
    children: ReactNode;
}

export const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType);

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [notification, setNotification] = useState<Notification>({ open: false, message: "", type: "success" });

  const showNotification = (message: string, type: AlertColor = "success") => {
    setNotification({ open: true, message, type });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={notification.type} sx={{ width: "100%" }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
