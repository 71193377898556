import { Avatar, Tooltip, IconButton, Box, Button, styled, InputBase, useTheme } from "@mui/material";
import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { useState } from "react";

type BottomBarContentProps = {
  handleClick: (e) => void;
  handleQuestion: (e) => void;
  tempQuestion: string;
  isBotThinking: boolean;
  isConnected: boolean;
};

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const Input = styled("input")({
  display: "none"
});

function BottomBarContent({ handleClick, handleQuestion, tempQuestion, isBotThinking, isConnected }: BottomBarContentProps) {
  const [status, setStatus] = useState("idle");
  const theme = useTheme();

  const user = {
    name: "Michael Hart",
    avatar: "/static/images/avatars/user_2.png"
  };

  function handleMessage(e) {
    setStatus("typing");
    // console.log("typing...:", e.target.value);
    return handleQuestion(e.target.value);
  }

  function handleSend(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      return handleClick(e);
    }
  }

  return (
    <Box
      sx={{
        background: theme.colors.alpha.white[50],
        display: "flex",
        alignItems: "center",
        p: 2
      }}
    >
      <Box flexGrow={1} display="flex" alignItems="center">
        <Avatar sx={{ display: { xs: "none", sm: "flex" }, mr: 1 }} alt={user.name} src={user.avatar} />
        <MessageInputWrapper
          autoFocus
          placeholder={isConnected ? (isBotThinking ? "waiting for the bot response..." : "Write your message here...") : "re-connecting..."}
          value={tempQuestion}
          onKeyDown={handleSend}
          onChange={handleMessage}
          fullWidth
          disabled={isBotThinking || !isConnected}
        />
      </Box>
      <Box>
        {/* <Tooltip arrow placement="top" title="Choose an emoji">
          <IconButton sx={{ fontSize: theme.typography.pxToRem(16) }} color="primary">
            😀
          </IconButton>
        </Tooltip>
        <Input accept="image/*" id="messenger-upload-file" type="file" />
        <Tooltip arrow placement="top" title="Attach a file">
          <label htmlFor="messenger-upload-file">
            <IconButton sx={{ mx: 1 }} color="primary" component="span">
              <AttachFileTwoToneIcon fontSize="small" />
            </IconButton>
          </label>
        </Tooltip> */}
        <Button disabled={isBotThinking || !isConnected} startIcon={<SendTwoToneIcon />} variant="contained" onClick={handleClick}>
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default BottomBarContent;
